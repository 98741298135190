body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
    text-align: center;
}

.App-link {
    color: #61dafb;
}

html {
    background-color: #00020e;
}

h1, h2, h3, h4, p {
    color: #f5f5f5;
}

.canvas {
    background-color: #00020e;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: 0;
}

.intro-section .navbar, .intro-section .title {
    background-color: unset;
}

.btn-info {
    background-color: #62d5ff;
    color: #00020e;
    border: 1px solid transparent;
}

.btn-info:hover {
    background-color: #00020e;
    color: #62d5ff;
    border-color: #62d5ff;
}

.info-section a {
    font-weight: bold;
}

.intro-section .row {
    height: 100%;
}

.intro-section h1 {
    text-transform: uppercase;
}

.intro-section .row #main-pic {
    height: calc(100vh - 57px - 48px);
    width: auto;
}

.footer {
    height: 150px;
    background-color: #62d5ff;
    color: #00020e;
    position: relative;
    z-index: 2;
}

.footer p {
    color: #00020e;
}

.footer a {
    color: black;
}
.link-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    left: 40px;
}
.cl-wrapper {
    position: relative;
    transition: all .15s ease-in;
}

.cl-wrapper:hover {
    -webkit-transform: scale(1.10);
            transform: scale(1.10);
}

#twitch-span:hover #twitch-circle,
#discord-span:hover #discord-circle,
#twitter-span:hover #twitter-circle,
#instagram-span:hover #instagram-circle,
#github-span:hover #github-circle {
    transition: color .5s ease;
    /* color: #f5f5f5; */
    border-radius: 50%;
    /* border: 2px solid #2A2D32; */
}

#twitch-span:hover #twitch{
    color: #6441a4;
}

#discord-span:hover #discord {
    color: #7289DA;
}

#twitter-span:hover #twitter {
    color: #1DA1F3;
}

#instagram-span:hover #instagram {
    color: #F00075;
}

.site-link {
    text-transform: capitalize;
}
.row .title #left-title, .row .title #right-title{
    font-size: 3.4rem;
}

.row #left-title {
    text-align: right;
    color: #f5f5f5;
}

.row #right-title {
    font-weight: bolder;
    text-align: left;
    color: #62d5ff;
}

.run-anim {
    -webkit-animation: fadeInOut 5s ease;
            animation: fadeInOut 5s ease;
}

@-webkit-keyframes fadeInOut {
    0% {
        opacity: 0;
    } 
    85% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    } 
    85% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.cr {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    z-index: 2;
}

.cr-order-right {
    background-color: hsl(196, 100%, 10%);
}

.cr-order-left .cr-text {
    background-color: #00020e;
}

/* Stream Page */
.cr-text {
    color: #f5f5f5;
    background-color: unset;
}
.content-row-image img {
    max-height: 400px;
    border-radius: 50%;
}

#emotes {
    border-radius: unset;
}
.twitch-embed-wrapper {
    background-color: unset;
}

.twitch-embed-wrapper p > a {
    color: #f5f5f5;
    font-weight: bold;
    text-decoration: underline;
}

.twitch-embed-wrapper p > a:hover {
    color: #62d5ff;
}
#portfolio-link {
    color: white;
}

#portfolio-link:hover {
    color: #62d5ff;
}
