.twitch-embed-wrapper {
    background-color: unset;
}

.twitch-embed-wrapper p > a {
    color: #f5f5f5;
    font-weight: bold;
    text-decoration: underline;
}

.twitch-embed-wrapper p > a:hover {
    color: #62d5ff;
}