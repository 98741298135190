.App {
    text-align: center;
}

.App-link {
    color: #61dafb;
}

html {
    background-color: #00020e;
}

h1, h2, h3, h4, p {
    color: #f5f5f5;
}

.canvas {
    background-color: #00020e;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: 0;
}

.intro-section .navbar, .intro-section .title {
    background-color: unset;
}

.btn-info {
    background-color: #62d5ff;
    color: #00020e;
    border: 1px solid transparent;
}

.btn-info:hover {
    background-color: #00020e;
    color: #62d5ff;
    border-color: #62d5ff;
}

.info-section a {
    font-weight: bold;
}

.intro-section .row {
    height: 100%;
}

.intro-section h1 {
    text-transform: uppercase;
}

.intro-section .row #main-pic {
    height: calc(100vh - 57px - 48px);
    width: auto;
}

.footer {
    height: 150px;
    background-color: #62d5ff;
    color: #00020e;
    position: relative;
    z-index: 2;
}

.footer p {
    color: #00020e;
}

.footer a {
    color: black;
}